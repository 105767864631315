import React from 'react'

const TopBanner = () => {
  return (
    <section className='promobar text-center p-1'>
      <span>Health fund rebate is now available</span>
    </section>
  )
}

export default TopBanner